import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { errorMessage } from "../../util/ConfirmAlertUtil";
import { successDefault } from "../../util/ToastUtil";
import Loading from "../UI/Loading";
import TextField from "../UI/TextField";
import { addList } from "./api-aluno-turma";

const AlunoTurmaFormList = () => {
  const [listCodigos, setListCodigos] = useState(null);
  const alunoTurmaRedux = useSelector((state) => state.alunoTurma);
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (event) => {
    setListCodigos(event.target.value);
  };

  const clickSubmit = (event) => {
    event.preventDefault();
    const list = listCodigos.split(",");
    setIsLoading(true);
    addList(list, alunoTurmaRedux.turma.codigo).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          successDefault();
        }
      }
      setIsLoading(false);
    });
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body1">
          Adicione os códigos dos alunos separados por , e adicione com a data
          de entrada atual. Todos serão adicionados ao mesmo tempo.
        </Typography>
        <Typography variant="body1">Ex: 458,459,460,7894,2154</Typography>
      </Grid>
      <Grid item>
        {isLoading ? (
          <Loading description="Adicionando todos os alunos..." />
        ) : (
          <form onSubmit={clickSubmit}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  label="Códigos"
                  onChange={onChange}
                  value={listCodigos}
                />
              </Grid>
              <Grid item>
                <Button type="submit" variant="outlined">
                  Adicionar
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Grid>
    </Grid>
  );
};

export default AlunoTurmaFormList;
